import axios from "~/plugins/axios";
const qs = require("qs");

export default {
  getAllAddress() {
    return axios
      .get("/ebsn/api/sogegross-all-address/list-all", {
        params: {}
      })
      .then(data => {
        return data.data.data;
      });
  },
  updateAddress(address) {
    return axios
      .post("/ebsn/api/sogegross-all-address/update", address)
      .then(data => {
        if (data.data.response.status == 0) {
          global.EventBus.$emit("success", {
            message: "addresses.setAddress"
          });
          return data.data.data;
        } else {
          return false;
        }
      });
  },
  requestAddressActivation(address) {
    return axios
      .get("/ebsn/api/sogegross-all-address/activate", {
        params: {
          address_id: address.addressId
        }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return false;
        }
      });
  },
  checkAddressActivationAlreadySent(address) {
    return axios
      .get("/ebsn/api/sogegross-all-address/already-sent", {
        params: {
          address_id: address.addressId
        }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return false;
        }
      });
  },
  registerUserWithCard(user) {
    let url = "/ebsn/api/sogegross-registration/register_user_with_card";
    return axios
      .post(url, user)
      .then(response => {
        return response.data.data.user;
      })
      .catch(err => err);
  },
  checkCard(user) {
    return axios
      .get("/ebsn/api/sogegross-registration/lookup_user", {
        params: { card: user.fidelityCard, piva_cf: user.person.fiscalCode }
      })
      .then(data => data.data)
      .catch(error => error);
  },
  registrationUser(user) {
    let url =
      "/ebsn/api/sogegross-registration/register_standard_user?registration_card_action=false";
    return axios
      .post(url, user)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  anonymizeAccountRequest() {
    return axios
      .get("/ebsn/api/sogegross-registration/anonymize_token_request")
      .then(data => {
        return data.data;
      })
      .catch(err => err);
  },
  anonymizeAccountConfirm(user, token) {
    return axios
      .get("/ebsn/api/sogegross-registration/anonymize_token_check", {
        params: { login: user, token: token }
      })
      .then(data => {
        return data.data.response;
      })
      .catch(err => err);
  },
  resetPassword(email, token, password) {
    let url = "/ebsn/api/registration-card/password_reset";
    return axios
      .post(url, qs.stringify({ email, token, password }))
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  getResetPasswordToken(email) {
    let url = "/ebsn/api/registration/password_reset_token_request";
    return axios
      .get(url, {
        params: { e_mail: email }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data;
        } else {
          Promise.reject(data);
        }
      });
  },
  confirmRegistration(email, controlCode, newEmail) {
    let url = "/ebsn/api/registration/registration_confirm";
    return axios
      .get(url, {
        params: {
          e_mail: email,
          reg_control_code: controlCode,
          new_email: newEmail
        }
      })
      .then(response => {
        return response.data.response;
      });
  },
  updatePassword(oldPassword, newPassword) {
    let url = "/ebsn/api/registration/update_password";
    return axios
      .get(url, {
        params: { old_password: oldPassword, new_password: newPassword }
      })
      .then(data => {
        return data.data;
      });
  }
};
