<template>
  <div v-if="category" class="category-title">
    <v-img
      v-if="headerImage && !$vuetify.breakpoint.xs"
      dark
      :src="headerImage"
      :height="height"
      class="elevation-0 rounded-0"
      @load="loaded = true"
    >
      <div class="layer"></div>
      <h1
        v-if="!category.metaData.category_info.HIDE_TITLE"
        class="pt-16 text-center main-category-title"
      >
        {{ title }}
      </h1>
    </v-img>
    <h1
      v-else-if="!category.metaData.category_info.HIDE_TITLE"
      class="pa-3 text-left grey--text text-h6 white"
    >
      <v-btn
        v-if="$platform_is_cordova && $routerHistory.hasPrevious()"
        elevation="0"
        outlined
        dark
        x-small
        :to="{ path: $routerHistory.previous().path }"
        class="back-button px-0"
        aria-label="Torna Indietro"
        ><v-icon medium elevation="0">mdi-chevron-left</v-icon></v-btn
      >
      <b>{{ title }}</b
      ><span v-if="count > 0">:{{ count }} prodotti</span>
    </h1>
    <v-container
      class="text-center text-sm-left"
      v-if="!category.metaData.category_info.HIDE_TITLE && subtitle"
      v-html="subtitle"
      @click="clicked"
    >
    </v-container>
  </div>
</template>
<style lang="scss">
.category.title {
  color: $category-title !important;
  h1 {
    font-size: 22px !important;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";

export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String, default: "170px" }
    // textColor: {type: String, default: '#636363'}
  },
  mixins: [clickHandler],

  data: function() {
    return { loaded: false };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    headerImage() {
      return get(this.category, "metaData.category_info.HEADER_IMAGE");
    }
  }
};
</script>
