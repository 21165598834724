<template>
  <div class="thank-you-page">
    <v-container fluid fill-height class="mt-5">
      <v-card flat class="elevation-0">
        <CategoryTitle :category="category" />
        <div class="response-content pa-3">
          <div v-if="infos && infos.length > 0">
            {{ infos[0].info }}
          </div>
          <div v-if="errors && errors.length > 0">
            {{ errors[0].error }}
          </div>
        </div>
        <v-card-actions class="justify-end">
          <div class="d-flex justify-end">
            <v-btn
              :to="{
                name: 'Home',
                path: '/'
              }"
              color="primary"
              large
              depressed
              block
            >
              Vai alla Home
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
import GrosMarketCustomService from "@/service/grosmarketCustomService";
import { mapActions } from "vuex";

export default {
  name: "AnonymizeConfirmed",
  components: { CategoryTitle },
  props: { showLogin: { type: Boolean, default: false } },
  data() {
    return { errors: [], infos: [], confirmed: false };
  },
  mixins: [categoryMixins, loginMixins],
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async doConfirm(login, token) {
      var _this = this;

      let response = await GrosMarketCustomService.anonymizeAccountConfirm(
        login,
        token
      );

      _this.errors.push(response.errors);
      _this.infos = response.infos;
      _this.confirmed = response.status == 0;
      if (_this.confirmed) {
        await this.doLogout();
      }
    }
  },
  created() {
    if (this.$route.query.login) {
      this.$route.query.login = this.$route.query.login.replace(" ", "+");
      this.doConfirm(this.$route.query.login, this.$route.query.token);
    }
  }
};
</script>

<style></style>
